import React from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { setSource } from "@app/store/filtersSlice";
import { RouterConfig } from "@shared/lib/routerConfig";
import { SLOGAN_ADDITIONAL_TEXT, SLOGAN_TEXT } from "@shared/config/GlobalText";
import { iconData } from "./config";
import { PlatformData } from "./types";
import classes from "./MainPageTitle.module.scss";

const MainPageTitle = () => {
    const dispatch = useDispatch();
    const router = useRouter();

    const onIconClick = (item: PlatformData) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        dispatch(setSource([item.platformId]));
        router.push(RouterConfig.CATALOG);
    };

    return (
        <div className={classes.MainPageTitle}>
            <h1 className={classes.MainPageTitle__text}>{SLOGAN_TEXT}</h1>
            <label className={classes.MainPageTitle__label}>{SLOGAN_ADDITIONAL_TEXT}</label>
            <ul className={classes.MainPageTitle__icons}>
                {iconData.map((item, index) => (
                    <li key={index}>
                        <button
                            className={classes["MainPageTitle__platform"]}
                            aria-label={item.label}
                            onClick={onIconClick(item)}
                        >
                            <item.Icon className={classes.MainPageTitle__item} />
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default MainPageTitle;