import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const BlogViewsIcon: FC<IconProps> = ({ className }) => (
    <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0629 11.9506C11.5189 12.4721 10.7774 12.7679 10.0003 12.7679C9.22321 12.7679 8.48173 12.472 7.9377 11.9506C7.39413 11.4299 7.09253 10.7279 7.09253 9.99982C7.09253 9.27178 7.3941 8.56981 7.93764 8.04911C8.48166 7.52766 9.2232 7.23175 10.0003 7.23175C10.7774 7.23175 11.5189 7.52763 12.0629 8.04905C12.6065 8.56974 12.9081 9.27177 12.9081 9.99982C12.9081 10.7279 12.6064 11.4299 12.0629 11.9506ZM11.0063 9.00995C10.7365 8.75158 10.3744 8.60939 10.0003 8.60939C9.62618 8.60939 9.26414 8.75158 8.99426 9.00994C8.72399 9.26915 8.56815 9.62481 8.56815 9.99985C8.56815 10.3749 8.72376 10.7303 8.99402 10.9895C9.2639 11.2479 9.62618 11.3903 10.0003 11.3903C10.3744 11.3903 10.7365 11.2481 11.0063 10.9898C11.2766 10.7305 11.4325 10.3749 11.4325 9.99985C11.4325 9.62481 11.2766 9.26916 11.0063 9.00995Z"
            fill="#747F94"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0003 16.6666C13.9641 16.6666 16.5614 13.6817 17.778 11.8407C18.1416 11.2881 18.3337 10.6499 18.3337 9.99992C18.3337 9.35006 18.1418 8.71201 17.7782 8.15953C16.5617 6.3185 13.9643 3.33325 10.0003 3.33325C6.0364 3.33325 3.43887 6.3185 2.22245 8.15947C1.85892 8.71197 1.66699 9.35004 1.66699 9.99992C1.66699 10.6499 1.85896 11.288 2.22259 11.8406C3.43916 13.6816 6.03656 16.6666 10.0003 16.6666ZM3.47409 8.88035L3.47204 8.88349C3.25394 9.21651 3.13737 9.60352 3.13737 9.99978C3.13737 10.396 3.25394 10.783 3.47204 11.1161L3.4741 11.1192C4.42845 12.5431 6.66796 15.2889 10.0003 15.2889C13.3328 15.2889 15.5696 12.5429 16.5265 11.1168L16.5288 11.1132C16.7461 10.7805 16.8625 10.3943 16.8625 9.99853C16.8625 9.60264 16.7462 9.2163 16.5288 8.88374L16.5263 8.87997C15.5694 7.45666 13.3328 4.71061 10.0003 4.71061C6.66796 4.71061 4.42853 7.45642 3.47409 8.88035Z"
            fill="#747F94"
        />
    </svg>
);