import type { NextPage } from "next";
import Template from "@app/Template/Template";
import { wrapper } from "@app/store/store";
import { getBrandsOptions } from "@app/store/filtersOptionsApi";
import MainScreen from "@components/Screens/MainScreen/MainScreen";
import BlogWidget from "@components/Features/Blog/BlogWidget/BlogWidget";
import { CatalogBrands } from "@widgets/CatalogBrands";
import { CustomHead, DEFAULT_OG_IMAGE } from "@shared/ui/CustomHead";
import { configApi } from "@shared/lib/configApi";
import { SchemaJsonLd } from "@shared/ui/SchemaJsonLd";
import { organizationSchema } from "@shared/ui/SchemaJsonLd/config";
import { Brand } from "@shared/lib/interfaces/brand.interface";

export interface HomeProps {
    preloadData: {
        brands: Brand[] | null;
    };
}

const Home: NextPage<HomeProps> = ({ preloadData }) => {
    return (
        <Template>
            <CustomHead
                title={"LIKVI.COM - Поиск автомобилей"}
                description={"Likvi.com - агрегатор автомобилей, продажа авто"}
                robots={"index,follow"}
                canonical={configApi.API_PUBLIC_DOMAIN}
                openGraph={{
                    title: "LIKVI.COM - Поиск автомобилей",
                    description: "Likvi.com - агрегатор автомобилей, продажа авто",
                    url: configApi.API_PUBLIC_DOMAIN,
                    type: "website",
                    image: {
                        url: DEFAULT_OG_IMAGE,
                        width: 1200,
                        height: 630,
                        alt: "LIKVI.COM - Поиск автомобилей"
                    }
                }}
            />
            <SchemaJsonLd schemas={[organizationSchema]} />
            <MainScreen />
            <BlogWidget title={"Блог"} showAll />
            <CatalogBrands brands={preloadData.brands} />
        </Template>
    );
};

export const getServerSideProps = wrapper.getServerSideProps((store) => async () => {
    const { data: brandsOptions } = await store.dispatch(getBrandsOptions.initiate({}));

    return {
        props: {
            preloadData: {
                brands: brandsOptions || null
            }
        }
    };
});

export default Home;